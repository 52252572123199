@import ../../../styles/helpers

.share
    position: relative

.button
    &.active
        background: $n2
        box-shadow: 0 0 0 2px $n2 inset
        color: $n8
        svg
            fill: $n8
        +dark
            background: $n3
            box-shadow: 0 0 0 2px $n3 inset

.body
    position: absolute
    left: 50%
    top: calc(100% + 8px)
    z-index: 2
    width: 220px
    transform: translateX(-50%)
    padding: 32px 16px
    background: $n8
    border: 1px solid $n6
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12)
    border-radius: 16px
    text-align: center
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: $n2
        border-color: $n3
    &.show
        visibility: visible
        opacity: 1

.openUp
    .body
        top: auto
        bottom: calc(100% + 8px)

.title
    margin-bottom: 24px
    font-weight: 500

.list
    display: flex
    justify-content: center

.link
    span
        display: flex
        justify-content: center
        align-items: center
        flex-shrink: 0
        width: 48px
        height: 48px
        border-radius: 50%
        background: $n6
        transition: all .2s
        svg
            transition: fill .2s
        &:hover
            background: $p1
            svg
                fill: $n8
        +dark
            background: $n3
            svg
                fill: $n8
            &:hover
                background: $p1
                svg
                    fill: $n8
    &:not(:last-child)
        margin-right: 24px

.darkButton
    .button
        box-shadow: 0 0 0 2px $n3 inset
        &.active
            background: $n2
            box-shadow: 0 0 0 2px $n2 inset
            color: $n8
            svg
                fill: $n8
    .body
        background: $n2
        border-color: $n3
        +m
            left: auto
            transform: translateX(0)
            right: -32px

    .title
        color: $n8
    .link
        span
            background: $n3
            svg
                fill: $n8
            &:hover
                background: $p1
                svg
                    fill: $n8