
.slick-prev:before, .slick-next:before
    color: #cfc9c9
.slick-slider
    position: relative
    display: block
    box-sizing: border-box
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    -webkit-touch-callout: none
    -khtml-user-select: none
    -ms-touch-action: pan-y
    touch-action: pan-y
    -webkit-tap-highlight-color: transparent

.slick-list
    position: relative
    display: block
    overflow: hidden
    margin: 0
    padding: 0

    &:focus
        outline: none

    &.dragging
        cursor: pointer
        cursor: hand

.slick-slider
    .slick-track, .slick-list
        -webkit-transform: translate3d(0, 0, 0)
        -moz-transform: translate3d(0, 0, 0)
        -ms-transform: translate3d(0, 0, 0)
        -o-transform: translate3d(0, 0, 0)
        transform: translate3d(0, 0, 0)

.slick-track
    position: relative
    top: 0
    left: 0
    display: flex
    align-items: center
    margin-left: auto
    margin-right: auto

    &:before
        display: table
        content: ''

    &:after
        display: table
        content: ''
        clear: both

.slick-loading .slick-track
    visibility: hidden

.timeframe-slider
    .slick-slide.slick-cloned.slick-center
        display: flex
        justify-content: space-evenly

.timeframe-slider
    .slick-slide.slick-active.slick-center
        display: flex
        justify-content: space-evenly

.slick-slide.slick-active.slick-center + .slick-slide:not(.slick-center)
    display: flex
    justify-content: left

.slick-slide.slick-active.slick-center ~ .slick-slide:not(.slick-center)
    display: flex
    justify-content: right

.slick-initialized
    display: block

.slick-loading 
    visibility: hidden

.slick-vertical
    display: block
    height: auto
    border: 1px solid transparent

.slick-arrow.slick-hidden
    display: none

.slick-arrow
    position: absolute
    z-index: 2
    width: 40px
    height: 40px
    border-radius: 50%
    font-size: 0
    transition: all .2s
    svg
        position: relative
        z-index: 2
        fill: $n4
        transition: fill .2s
    &:hover
        box-shadow: inset 0 0 0 2px $n6
    +dark-common
        &:hover
            box-shadow: inset 0 0 0 2px $n3
            svg
                fill: $n6
    // &.slick-disabled
    //     opacity: .7

.learn-slider
    .slick-arrow
        bottom: 0
    .slick-prev
        left: calc(50% - 50px)
        top: auto
    .slick-next
        right: calc(50% - 50px)
        top: auto
.steps-slider
    .slick-arrow
        bottom: 0
    .slick-prev
        left: calc(50% - 50px)
        top: auto
    .slick-next
        right: calc(50% - 50px)
        top: auto


// popular slider
.popular-slider
    +t
        padding-bottom: 72px
    .slick-list
        // overflow: visible
    .slick-track
        display: flex
    .slick-arrow
        bottom: 0
    .slick-prev
        left: calc(50% - 50px)
        top: auto
    .slick-next
        right: calc(50% - 50px)
        top: auto
        
// learn slider
.learn-slider
    +d
        padding-bottom: 72px
    .slick-list
        // overflow: visible
    .slick-arrow
        bottom: 0
    .slick-prev
        left: 16px
        +m
            left: calc(50% - 48px)
    .slick-next
        left: 64px
        +m
            left: auto
            right: calc(50% - 48px)

// education slider
.education-slider
    padding-bottom: 88px
    .slick-list
        // overflow: visible
    .slick-slide
        opacity: 0
        transition: opacity .4s
        &.slick-active
            opacity: 1
        &:nth-child(2n+1)
            .education-preview
                background: $n7
                +dark-common
                    background: #323642
        &:nth-child(2n)
            .education-preview
                background: $n6
                +dark-common
                    background: #23262F
    .slick-arrow
        bottom: 0
    .slick-prev
        left: calc(50% - 48px)
    .slick-next
        right: calc(50% - 48px)

// news slider
.news-slider
    +m
        padding-bottom: 72px
    .slick-list
        // overflow: visible
    .slick-slide
        opacity: 0
        transition: opacity .4s
        &.slick-active
            opacity: 1
    .slick-arrow
        top: 50%
        transform: translateY(-50%)
        +m
            top: auto
            bottom: 0
            transform: translateY(0)
    .slick-prev
        left: 0
        +m
            left: calc(50% - 48px)
    .slick-next
        right: 0
        +m
            right: calc(50% - 48px)