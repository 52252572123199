@import url('../node_modules/@fortawesome/fontawesome-free/css/all.css');
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

html {
  font-size: 14px;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: #f9fffe;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #181b21;
}

#root {
  min-height: inherit;
  height: inherit;
  min-width: inherit;
  width: inherit;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f9fffe inset;
  box-shadow: 0 0 0 1000px #f9fffe inset;
}

.picker-container .picker-inner {
  @media (max-width: 600px) {
    padding: 0 !important;
  }
}

.picker-container .picker-column .picker-item {
  @media (max-width: 600px) {
    padding: 0 !important;
  }
}
